import _nuxt_components_plugin_mjs_KR1HBZs4kY from "D:/a/1/s/projects/integrations/Logomark/Customizer.Integrations.Logomark.Dashboard/source/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "D:/a/1/s/projects/integrations/Logomark/Customizer.Integrations.Logomark.Dashboard/source/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_mixin_plugin_mjs_prWV5EzJWI from "D:/a/1/s/projects/integrations/Logomark/Customizer.Integrations.Logomark.Dashboard/source/node_modules/nuxt/dist/head/runtime/mixin-plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "D:/a/1/s/projects/integrations/Logomark/Customizer.Integrations.Logomark.Dashboard/source/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc from "D:/a/1/s/projects/integrations/Logomark/Customizer.Integrations.Logomark.Dashboard/source/node_modules/nuxt/dist/app/plugins/payload.client.mjs";
import plugins_animate_js_0v16OsOpyE from "D:/a/1/s/projects/integrations/Logomark/Customizer.Integrations.Logomark.Dashboard/source/plugins/animate.js";
import plugins_validate_js_wD3rDnOmpV from "D:/a/1/s/projects/integrations/Logomark/Customizer.Integrations.Logomark.Dashboard/source/plugins/validate.js";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_head_runtime_mixin_plugin_mjs_prWV5EzJWI,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc,
  plugins_animate_js_0v16OsOpyE,
  plugins_validate_js_wD3rDnOmpV
]