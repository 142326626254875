import { defineRule, configure } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import {
    required,
    email,
} from '@vee-validate/rules';

defineRule('required', required);
defineRule('email', email);

configure({
    generateMessage: localize('en', {
        messages: {
            required: '{field} is required',
            email: 'Should be a valid email address',
        },
    }),
});

export default defineNuxtPlugin(() => {
});
