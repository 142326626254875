<template>
    <vee-field
        :name="name"
        :label="label"
        :rules="rules"
        v-slot="{ field, errors }"
        :value="modelValue"
        @input="updateValue"
    >
        <div v-auto-animate>
            <input v-bind="field"
                :id="id"
                :name="name"
                :type="type"
                :autocomplete="autocomplete"
                class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500"
                :class="[
                    errors.length > 0 ? ['border-red-300', 'text-red-900', 'placeholder-red-300', 'focus:border-red-500', 'focus:ring-red-500'] : [],
                ]"
            />
            <p v-if="errors.length > 0" class="mt-2 text-red-600">{{errors[0]}}</p>
        </div>
    </vee-field>
</template>

<script>
    import { Field } from 'vee-validate';

    export default {
        components: {
            VeeField: Field,
        },

        props: {
            id: {
                type: String,
                default: null,
            },
            name: {
                type: String,
                default: null,
            },
            label: {
                type: String,
                default: null,
            },
            type: {
                type: String,
                default: null,
            },
            autocomplete: {
                type: String,
                default: null,
            },
            rules: {
                type: String,
                default: null,
            },
            modelValue: {
                type: String,
                default: null,
            },
        },

        methods: {
            updateValue(event) {
                this.$emit('update:modelValue', event.target.value);
            },
        },
    };
</script>
