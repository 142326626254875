import { meta as D_58_47a_471_47s_47projects_47integrations_47Logomark_47Customizer_46Integrations_46Logomark_46Dashboard_47source_47pages_47index_46vueMeta } from "D:/a/1/s/projects/integrations/Logomark/Customizer.Integrations.Logomark.Dashboard/source/pages/index.vue?macro=true";
import { meta as D_58_47a_471_47s_47projects_47integrations_47Logomark_47Customizer_46Integrations_46Logomark_46Dashboard_47source_47pages_47login_46vueMeta } from "D:/a/1/s/projects/integrations/Logomark/Customizer.Integrations.Logomark.Dashboard/source/pages/login.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    file: "D:/a/1/s/projects/integrations/Logomark/Customizer.Integrations.Logomark.Dashboard/source/pages/index.vue",
    children: [],
    meta: D_58_47a_471_47s_47projects_47integrations_47Logomark_47Customizer_46Integrations_46Logomark_46Dashboard_47source_47pages_47index_46vueMeta,
    alias: D_58_47a_471_47s_47projects_47integrations_47Logomark_47Customizer_46Integrations_46Logomark_46Dashboard_47source_47pages_47index_46vueMeta?.alias || [],
    redirect: D_58_47a_471_47s_47projects_47integrations_47Logomark_47Customizer_46Integrations_46Logomark_46Dashboard_47source_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/a/1/s/projects/integrations/Logomark/Customizer.Integrations.Logomark.Dashboard/source/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    file: "D:/a/1/s/projects/integrations/Logomark/Customizer.Integrations.Logomark.Dashboard/source/pages/login.vue",
    children: [],
    meta: D_58_47a_471_47s_47projects_47integrations_47Logomark_47Customizer_46Integrations_46Logomark_46Dashboard_47source_47pages_47login_46vueMeta,
    alias: D_58_47a_471_47s_47projects_47integrations_47Logomark_47Customizer_46Integrations_46Logomark_46Dashboard_47source_47pages_47login_46vueMeta?.alias || [],
    redirect: D_58_47a_471_47s_47projects_47integrations_47Logomark_47Customizer_46Integrations_46Logomark_46Dashboard_47source_47pages_47login_46vueMeta?.redirect || undefined,
    component: () => import("D:/a/1/s/projects/integrations/Logomark/Customizer.Integrations.Logomark.Dashboard/source/pages/login.vue").then(m => m.default || m)
  }
]