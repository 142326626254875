<template>
    <transition-root as="template" :show="open">
        <ui-dialog as="div" class="relative z-10" @close="close">
            <transition-child as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </transition-child>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <transition-child as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <dialog-panel class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                            <div>
                                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                                    <i class="fa-solid fa-exclamation text-xl text-red-600" aria-hidden="true"></i>
                                </div>
                                <div class="mt-3 text-center sm:mt-5">
                                    <dialog-title as="h3" class="text-lg font-medium leading-6 text-gray-900">{{title}}</dialog-title>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-6">
                                <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                    @click.prevent="close"
                                >
                                    {{button}}
                                </button>
                            </div>
                        </dialog-panel>
                    </transition-child>
                </div>
            </div>
        </ui-dialog>
    </transition-root>
</template>

<script>
    import {
        Dialog,
        DialogPanel,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
    } from '@headlessui/vue';

    export default {
        components: {
            UiDialog: Dialog,
            DialogPanel,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
        },

        props: {
            open: {
                type: Boolean,
            },

            title: {
                type: String,
            },

            button: {
                type: String,
            },
        },

        methods: {
            close() {
                this.$emit('close');
            },
        },
    };
</script>
